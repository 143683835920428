import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const Design = () => {
  return (
    <Layout>
      <SEO title="Design" />
      <div>
        <section className="content mt-5 mb-5">
          <p>
            we use Ubuntu as our main font. sized in accordance with the
            "perfect fourth" scale. it looks like this:
          </p>
          <h1 className={"mt-3"}>we are cruto</h1>
          <h2 className={"mt-3"}>we are cruto</h2>
          <h3 className={"mt-3"}>we are cruto</h3>
          <h4 className={"mt-3"}>we are cruto</h4>
          <p className={"mt-3 large"}>we are cruto</p>
          <p className={"mt-3"}>we are cruto</p>
          <p className={"mt-3 small"}>we are cruto</p>
        </section>
      </div>
    </Layout>
  )
}

export default Design
